@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,700&display=swap');

body {
  font-family: 'Poppins', sans-serif;
}

@layer base {
  html {
    scroll-behavior: smooth;
  }

  /* Add this block for smooth transitions */
  * {
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  }
}

@layer utilities {
  .animate-text-shadow {
    animation: text-shadow-pulse 2s infinite;
  }

  @keyframes text-shadow-pulse {
    0% {
      text-shadow: 0 0 0 rgba(0, 255, 255, 0.7), 0 0 0 rgba(255, 0, 255, 0.7);
    }
    50% {
      text-shadow: 0 0 20px rgba(0, 255, 255, 0.7), 0 0 20px rgba(255, 0, 255, 0.7);
    }
    100% {
      text-shadow: 0 0 0 rgba(0, 255, 255, 0.7), 0 0 0 rgba(255, 0, 255, 0.7);
    }
  }

  .animate-fade-in-up {
    animation: fade-in-up 1s ease-out;
  }

  @keyframes fade-in-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .animation-delay-200 {
    animation-delay: 200ms;
  }

  .animation-delay-400 {
    animation-delay: 400ms;
  }

  .animate-blur-in {
    animation: blur-in 1s ease-out forwards;
  }

  @keyframes blur-in {
    0% {
      filter: blur(5px);
    }
    100% {
      filter: blur(0);
    }
  }
}

.dark {
  color-scheme: dark;
}

/* Add these specific transitions */
body,
.bg-gray-100,
.bg-gray-800,
.bg-gray-900,
.bg-white {
  transition: background-color 0.3s ease;
}

.text-white,
.text-gray-900,
.text-gray-300,
.text-gray-600,
.text-gray-700 {
  transition: color 0.3s ease;
}

input,
textarea,
button {
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}